import type { NextPage } from 'next'
import Head from 'next/head';
import { useEffect } from 'react';

const Home: NextPage = () => {
  useEffect(()=>{
    window.location.href = 'https://travelnest.com';
  },[]);
  
  return (
    <>
      <Head>
        <title>Travelnest Direct Bookings</title>
        <meta name="description" content="Travelnest Direct Bookings" />
      </Head>
    </>
  )
}

export default Home
